const checkDevAndLocal = () => {
    switch (process.env.REACT_APP_DOMAIN) {
        case "https://dev.positivehotelyoga.com":
            return "dev";
        case "http://localhost:3000":
            return "local";
        default:
            return undefined;
    }
}

export { checkDevAndLocal }